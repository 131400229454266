import { Module } from "vuex";

import { ProductState, RootState } from "@/store/types";
import { ProductResponse } from "@/models/ProductResponse";
import { PropertyResponse } from "@/models/PropertyResponse";
import ApiService from "@/services/ApiService";

export const productModule: Module<ProductState, RootState> = {
  namespaced: true,
  state: () => ({
    products: [],
    properties: [],
  }),
  mutations: {
    setProducts(state, products: ProductResponse[]): void {
      state.products = products;
    },
    setProperties(state, properties: PropertyResponse[]): void {
      state.properties = properties;
    },
  },
  actions: {
    async fetchProducts({ commit }, collectionId: number): Promise<void> {
      const response = await ApiService.products(collectionId);
      commit("setProducts", response.products.changed);
      commit("setProperties", response.properties);
    },
  },
};
