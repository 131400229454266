import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5e836a9b"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"
import style0 from "./App.vue?vue&type=style&index=0&id=5e836a9b&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VAlert,VApp,VCol,VFooter,VMain})
