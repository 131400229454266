import { render, staticRenderFns } from "./ProductReferenceDialog.vue?vue&type=template&id=3685a64e"
import script from "./ProductReferenceDialog.vue?vue&type=script&lang=ts"
export * from "./ProductReferenceDialog.vue?vue&type=script&lang=ts"
import style0 from "./ProductReferenceDialog.vue?vue&type=style&index=0&id=3685a64e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCol,VMenu,VSimpleTable})
