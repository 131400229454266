import "./styles/main.scss";
import "./plugins/class-component-hooks";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

window.onbeforeunload = () => {
  if (
    router.currentRoute.name == "collection" &&
    store.getters["rating/hasChanges"]
  ) {
    return "You have unsaved changes.";
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
