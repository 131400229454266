import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#112169",
        primaryDarkest: "#181E3A",
        gold: "#E6A626",
      },
    },
  },
});
