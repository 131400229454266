import { RootState, UserInfo } from "@/store/types";
import { ratingModule } from "@/store/ratingModule";
import { collectionModule } from "@/store/collectionModule";
import { productModule } from "@/store/productModule";

import Vue from "vue";
import Vuex from "vuex";
import { createVuexPlugin } from "@weareyipyip/multitab-token-refresh";

Vue.use(Vuex);

const userInfoKey = "userInfo";

function persistUserInfo(userInfo: UserInfo | null): void {
  if (userInfo == null) {
    localStorage.removeItem(userInfoKey);
  } else {
    localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
  }
}

function getUserInfo(): UserInfo | null {
  const userInfo = localStorage.getItem(userInfoKey);
  return userInfo == null ? null : (JSON.parse(userInfo) as UserInfo);
}

export default new Vuex.Store<RootState>({
  plugins: [createVuexPlugin("updateAuthStatus")],
  state: {
    loggedIn: false,
    userInfo: getUserInfo(),
    errorMessage: null,
  },
  getters: {},
  mutations: {
    updateAuthStatus(state, { loggedIn }: { loggedIn: boolean }): void {
      if (!loggedIn) {
        state.userInfo = null;
        persistUserInfo(null);
      }

      state.loggedIn = loggedIn;
    },
    setUserInfo(
      state,
      { name, email, margin }: { name: string; email: string; margin: boolean }
    ): void {
      persistUserInfo({ name, email, margin });
      state.userInfo = { name, email, margin };
    },
    setErrorMessage(state, errorMessage: string | null): void {
      state.errorMessage = errorMessage;
    },
  },
  actions: {},
  modules: {
    collection: collectionModule,
    product: productModule,
    rating: ratingModule,
  },
});
