enum Environment {
  Production,
  Staging,
  Development,
}

class Settings {
  get environment(): Environment {
    switch (window.location.hostname) {
      case "webapp.i-genova.com":
        return Environment.Production;
      case "staging-webapp.i-genova.com":
        return Environment.Staging;
      default:
        return Environment.Development;
    }
  }

  get apiUrl(): string {
    switch (this.environment) {
      case Environment.Production:
        return "https://api.i-genova.com";
      case Environment.Staging:
        return "https://staging-api.i-genova.com";
      case Environment.Development:
        return "/api";
    }
  }

  get forgotPasswordUrl(): string {
    switch (this.environment) {
      case Environment.Production:
        return "https://www.i-genova.com/forgot_password";
      case Environment.Staging:
        return "https://staging-backend.i-genova.com/forgot_password";
      case Environment.Development:
        return "http://localhost:4040/forgot_password";
    }
  }
}

export default new Settings();
