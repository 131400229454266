import { Module } from "vuex";

import { CollectionState, RootState } from "@/store/types";
import { CollectionResponse } from "@/models/CollectionResponse";
import ApiService from "@/services/ApiService";

export const collectionModule: Module<CollectionState, RootState> = {
  namespaced: true,
  state: () => ({
    collections: [],
  }),
  getters: {
    collectionById(state): Record<number, CollectionResponse> {
      const collectionById: Record<number, CollectionResponse> = {};

      for (const collection of state.collections) {
        collectionById[collection.id] = collection;
      }

      return collectionById;
    },
  },
  mutations: {
    setCollections(state, collections: CollectionResponse[]): void {
      state.collections = collections;
    },
  },
  actions: {
    async fetchCollections({ commit }): Promise<void> {
      const response = await ApiService.collections();
      commit("setCollections", response.collections);
    },
  },
};
