import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import CollectionsView from "../views/CollectionsView.vue";
import LoginView from "../views/LoginView.vue";
import CollectionView from "../views/CollectionView.vue";
import Navigation from "../components/Navigation.vue";
import LogoutView from "../views/LogoutView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    redirect: {
      name: "collections",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
  },
  {
    path: "/collections",
    name: "collections",
    components: {
      default: CollectionsView,
      navigation: Navigation,
    },
  },
  {
    path: "/collections/:collectionId",
    name: "collection",
    props: {
      default: (route) => ({
        collectionId: parseInt(route.params.collectionId),
      }),
    },
    components: {
      default: CollectionView,
      navigation: Navigation,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setErrorMessage", null);

  if (!store.state.loggedIn && to.name != "login") {
    next({ name: "login" });
  } else if (store.state.loggedIn && to.name == "login") {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
