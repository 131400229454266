/**
 * Computes the margin based on the given report and cost price.
 *
 * @returns A human readable margin.
 */
export function displayMargin(reportPrice: number, costPrice: number): string {
  if (costPrice == 0) {
    return "?";
  }

  const margin = ((reportPrice - costPrice) / reportPrice) * 100;

  return `${margin.toFixed(2)}%`;
}

type Rating = "strong" | "ok" | "weak" | "no" | null;

export function sortRating(a: Rating, b: Rating): number {
  const ratingOrder = [null, "no", "weak", "ok", "strong"];
  return ratingOrder.indexOf(a) - ratingOrder.indexOf(b);
}
