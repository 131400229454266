import { LoginResponse } from "@/models/LoginResponse";
import { ErrorResponse } from "@/models/ErrorResponse";
import { CollectionsResponse } from "@/models/CollectionsResponse";
import { RatingsResponse } from "@/models/RatingsResponse";
import { RefreshTokenResponse } from "@/models/RefreshTokenResponse";
import { ProductsResponse } from "@/models/ProductsResponse";
import { Rating } from "@/models/Rating";
import Settings from "@/services/Settings";

import Axios from "axios";
import {
  TokenService,
  fromJwt,
  axiosAuthRequestInterceptor,
} from "@weareyipyip/multitab-token-refresh";
import { ProductExtendedResponse } from "@/models/ProductResponse";

class ApiService {
  private readonly anonymous = Axios.create({ baseURL: Settings.apiUrl });
  private readonly authenticated = Axios.create({ baseURL: Settings.apiUrl });

  private async onAuthSuccess<T>(response: T & { token: string }): Promise<T> {
    await TokenService.updateStatus(
      fromJwt({ accessToken: response.token, refreshToken: response.token })
    );
    return response;
  }

  private onAuthFailure(error: unknown): unknown {
    TokenService.setLoggedOut();
    return error;
  }

  constructor() {
    this.anonymous.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(ErrorResponse.fromAxiosError(error))
    );

    this.authenticated.interceptors.request.use(axiosAuthRequestInterceptor);
    this.authenticated.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(ErrorResponse.fromAxiosError(error))
    );

    TokenService.setRefreshCallback(async (refreshToken) =>
      this.refreshToken(refreshToken)
    );
  }

  async login(username: string, password: string): Promise<LoginResponse> {
    try {
      const response = await this.anonymous.get("/users/login", {
        auth: { username, password },
      });

      const loginResponse = response.data.response as LoginResponse;

      if (loginResponse.user.role !== "client") {
        throw new Error("Invalid role");
      }

      return await this.onAuthSuccess(loginResponse);
    } catch (error) {
      throw this.onAuthFailure(error);
    }
  }

  async refreshToken(refreshToken: string): Promise<RefreshTokenResponse> {
    try {
      const response = await this.anonymous.get("/users/refresh-token", {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
      });

      return this.onAuthSuccess<RefreshTokenResponse>(response.data.response);
    } catch (error) {
      throw this.onAuthFailure(error);
    }
  }

  async collections(): Promise<CollectionsResponse> {
    const response = await this.authenticated.get("/collections/index");

    return response.data.response as CollectionsResponse;
  }

  async products(collectionId: number): Promise<ProductsResponse> {
    const response = await this.authenticated.get(
      `/collections/update/${collectionId}/0`
    );

    return response.data.response as ProductsResponse;
  }

  /** Finds a product under a company through matching article codes.
   */
  async getProductByCodes(
    collectionCode: string,
    companyId: number,
    articleCodes: Record<string, string>
  ): Promise<ProductExtendedResponse> {
    const response = await this.authenticated.get(
      `/companies/${companyId}/products/${collectionCode}`,
      {
        params: articleCodes,
      }
    );

    return response.data as ProductExtendedResponse;
  }

  async ratings(collectionId: number): Promise<RatingsResponse> {
    const response = await this.authenticated.get(
      `/products/ratings/${collectionId}`
    );

    return response.data.response as RatingsResponse;
  }

  async rate(ratings: (Rating & { productId: number })[]): Promise<void> {
    await this.authenticated.post("/products/rate", {
      ratings: ratings.map((r) => ({
        product_id: r.productId,
        status: r.status,
        amount: r.amount,
        remarks: r.remarks,
      })),
    });
  }

  async userExport(collectionId: number): Promise<Blob> {
    const response = await this.authenticated.get(
      `/collections/userExport/${collectionId}/download`,
      { responseType: "blob" }
    );

    return response.data as Blob;
  }
}

export default new ApiService();
