import Vue from "vue";
import { Module } from "vuex";

import { RatingResponse } from "@/models/RatingResponse";
import { Rating } from "@/models/Rating";
import { RatingChange, RatingState, RootState } from "@/store/types";
import ApiService from "@/services/ApiService";

export const ratingModule: Module<RatingState, RootState> = {
  namespaced: true,
  state: () => ({
    ratings: {},
    changes: {},
  }),
  getters: {
    hasChanges(state): boolean {
      return Object.keys(state.changes).length > 0;
    },
    mergedRatings(state): Record<number, Rating> {
      return {
        ...state.ratings,
        ...state.changes,
      };
    },
  },
  mutations: {
    setRatings(state, ratings: RatingResponse[]): void {
      const ratingsByProductId: Record<number, RatingResponse> = {};

      for (const rating of ratings) {
        ratingsByProductId[rating.product_id] = rating;
      }

      state.ratings = ratingsByProductId;
      state.changes = {};
    },
    addChange(
      state,
      { productId, change }: { productId: number; change: RatingChange }
    ): void {
      Vue.set(state.changes, productId, change);
    },
  },
  actions: {
    async fetchRatings({ commit }, collectionId: number): Promise<void> {
      const response = await ApiService.ratings(collectionId);
      commit("setRatings", response.result);
    },
    async saveRatings(
      { state, dispatch },
      collectionId: number
    ): Promise<void> {
      const productIds = Object.keys(state.changes) as unknown as Array<number>;

      const changes = productIds.map((productId) => {
        const change = state.changes[productId];
        return {
          productId,
          status: change.status,
          amount: change.amount,
          remarks: change.remarks,
        };
      });

      await ApiService.rate(changes);
      await dispatch("fetchRatings", collectionId);
    },
  },
};
