import { AxiosError } from "axios";

type ReasonResponse = {
  response?: {
    reason?: string;
  };
};

export class ErrorResponse {
  private constructor(public readonly reason: string) {}

  static fromAxiosError(error: unknown): ErrorResponse | unknown {
    if (error instanceof AxiosError) {
      const data = error.response?.data as ReasonResponse | undefined;
      const reason = data?.response?.reason;

      if (reason) {
        return new ErrorResponse(reason);
      }
    }

    return error;
  }
}
